/*-------------------------------
             Login 
-------------------------------*/

body {
  font-family: "Poppins", sans-serif;
  height: 100vh;
}

/* STRUCTURE */

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}

#formContent {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 90%;
  max-width: 450px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

#formFooter {
  background-color: #f6f6f6;
  border-top: 1px solid #dce8f1;
  padding: 25px;
  text-align: center;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}

/* TABS */

h2.inactive {
  color: #cccccc;
}

h2.active {
  color: #0d0d0d;
  border-bottom: 2px solid #5fbae9;
}

/* FORM TYPOGRAPHY*/

input[type="button"],
input[type="submit"],
input[type="reset"] {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  box-shadow: 0 10px 30px 0 rgba(95, 186, 233, 0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
  background-color: #39ace7;
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
  -moz-transform: scale(0.95);
  -webkit-transform: scale(0.95);
  -o-transform: scale(0.95);
  -ms-transform: scale(0.95);
  transform: scale(0.95);
}

.login-email,
input[type="password"] {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.login-email:focus,
input[type="password"]:focus {
  background-color: #fff;
  border-bottom: 2px solid #5fbae9;
}

.login-email:placeholder,
input[type="password"]:placeholder {
  color: #cccccc;
}

/* ANIMATIONS */

/* Simple CSS3 Fade-in-down Animation */
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

/* Simple CSS3 Fade-in Animation */
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  -webkit-animation: fadeIn ease-in 1;
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}

.fadeIn.first {
  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

.fadeIn.second {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.fadeIn.third {
  -webkit-animation-delay: 0.8s;
  -moz-animation-delay: 0.8s;
  animation-delay: 0.8s;
}

.fadeIn.fourth {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

/* Simple CSS3 Fade-in Animation */
.underlineHover:after {
  display: block;
  left: 0;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #56baed;
  content: "";
  transition: width 0.2s;
}

.underlineHover:hover {
  color: #0d0d0d;
}

.underlineHover:hover:after {
  width: 100%;
}

h1 {
  color: #60a0ff;
}

/* OTHERS */

*:focus {
  outline: none;
}

#icon {
  width: 30%;
}
/*-------------------------------
             Login 
-------------------------------*/

/*-------------------------------
             Header 
-------------------------------*/
.app-header {
  position: sticky;
  top: 0;
  color: #fff;
  text-align: right;
  padding: 15px;
  background: #31353d;
  width: 100% !important;
  /* overflow: auto; */
  z-index: 1000;
}

.app-header #nav {
  width: 5%;
  margin-right: 5px;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.app-header #nav:hover {
  text-decoration: none;
  background: white;
  color: #000;
}

.app-header .badge {
  background: red;
  margin-top: -20px;
}

#notifications {
  padding-top: 20px 10px 20px 10px;
  overflow-y: auto;
  height: 400px;
}

#notifications .date {
  margin-left: 3px;
}

#notifications .order-by {
  margin-right: 3px;
}
#notifications li {
  padding: 5px;
  border-bottom: 1px solid #282c33;
  transition: all 0.3s;
  margin: 0 10px 2px 10px;
}
/* #notifications li:hover {
	border-top-left-radius: 20px;
	border-bottom-right-radius: 20px;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0;
} */
/* #notifications b {
	color: #2266a5;
	font-size: 1em;
} */

/*-------------------------------
             Header 
-------------------------------*/

/*-------------------------------
             Profile 
-------------------------------*/
#profile-pic img {
  position: relative;
}
.pic-update-btn {
  position: absolute;
  bottom: 20px;
  left: 34%;
  cursor: pointer;
  color: white;
  background: #525965;
  padding: 10px 5px 10px 38px;
  transition: all 0.5s;
  border-radius: 10px;
  width: 180px;
  height: 40px;
}
.pic-update-btn:hover {
  background: #fff;
  color: #525965;
  box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19),
    2px 2px 6px 2px rgba(97, 95, 95, 0.19) !important ;
}
#profile-image-input {
  opacity: 0;
}
/*-------------------------------
             Profile 
-------------------------------*/

/* -----------------------------
            SideBar 
-------------------------------*/
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(5deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes sonar {
  0% {
    transform: scale(0.9);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
.page-wrapper .sidebar-wrapper,
.sidebar-wrapper .sidebar-brand > a,
.sidebar-wrapper .sidebar-dropdown > a:after,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
.sidebar-wrapper ul li a i,
.page-wrapper .page-content,
.sidebar-wrapper .sidebar-search input.search-menu,
.sidebar-wrapper .sidebar-search .input-group-text,
.sidebar-wrapper .sidebar-menu ul li a,
#show-sidebar,
#close-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/*----------------page-wrapper----------------*/

.page-wrapper {
  height: 100vh;
}

.page-wrapper .theme {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 4px;
  margin: 2px;
}

.page-wrapper .theme.chiller-theme {
  background: #1e2229;
}

/*----------------toggeled sidebar----------------*/

.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}

@media screen and (min-width: 768px) {
  .page-wrapper.toggled .page-content {
    padding-left: 250px;
  }
}
/*----------------show sidebar button----------------*/
#show-sidebar {
  position: fixed;
  left: 0;
  top: 60px;
  border-radius: 0 4px 4px 0px;
  width: 35px;
  transition-delay: 0.3s;
}
.page-wrapper.toggled #show-sidebar {
  left: -40px;
}
/*----------------sidebar-wrapper----------------*/

.sidebar-wrapper {
  width: 260px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 1000;
}

.sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-wrapper a {
  text-decoration: none;
}

/*----------------sidebar-content----------------*/

.sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}

.sidebar-content.desktop {
  overflow-y: hidden;
}

/*--------------------sidebar-brand----------------------*/

.sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
  padding: 20px;
}

.sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 20px;
}
/*--------------------sidebar-header----------------------*/

.sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}

/*----------------------sidebar-menu-------------------------*/

.sidebar-wrapper .sidebar-menu {
  padding-bottom: 10px;
}

.sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}

.sidebar-wrapper .sidebar-menu ul li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 30px 8px 20px;
}

.sidebar-wrapper .sidebar-menu ul li a i {
  margin-right: 10px;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 4px;
}

.sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
  display: inline-block;
  animation: swing ease-in-out 0.5s 1 alternate;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  font-style: normal;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: 0 0;
  position: absolute;
  right: 15px;
  top: 14px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
  padding-left: 25px;
  font-size: 13px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
  content: "\f111";
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
  font-style: normal;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
  font-size: 8px;
}

.sidebar-wrapper .sidebar-menu ul li a span.label,
.sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
.sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
  float: right;
  margin-top: 0px;
}

.sidebar-wrapper .sidebar-menu .sidebar-submenu {
  display: none;
}

.sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}

/*--------------------------page-content-----------------------------*/

.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  padding-top: 10px;
}

.page-wrapper .page-content > div {
  padding: 20px 40px;
}

.page-wrapper .page-content {
  overflow-x: hidden;
}

/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #525965;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #525965;
}
::-webkit-scrollbar-thumb:active {
  background: #525965;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

/*-----------------------------chiller-theme-------------------------------------------------*/

.chiller-theme .sidebar-wrapper {
  background: #31353d;
}

.chiller-theme .sidebar-wrapper .sidebar-menu {
  border-top: 1px solid #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  border-color: transparent;
  box-shadow: none;
}

.chiller-theme .sidebar-wrapper .sidebar-brand > a,
.chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
.chiller-theme .sidebar-footer > a {
  color: #818896;
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover > a,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a,
.chiller-theme .sidebar-footer > a:hover i {
  color: #fff;
  padding-left: 30px;
  transform: scale(1.1);
  background: #464a52;
}

.page-wrapper.chiller-theme.toggled #close-sidebar {
  color: #bdbdbd;
}

.page-wrapper.chiller-theme.toggled #close-sidebar:hover {
  color: #ffffff;
}

.chiller-theme .sidebar-wrapper ul li:hover a i,
.chiller-theme
  .sidebar-wrapper
  .sidebar-dropdown
  .sidebar-submenu
  li
  a:hover:before,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus + span,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
  color: #16c7ff;
  text-shadow: 0px 0px 10px rgba(22, 199, 255, 0.5);
}

.chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
.chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
.chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
.chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
  background: #3a3f48;
}

.chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
  color: #6c7b88;
}

.chiller-theme .sidebar-footer {
  background: #3a3f48;
  box-shadow: 0px -1px 5px #282c33;
  border-top: 1px solid #464a52;
}

.chiller-theme .sidebar-footer > a:first-child {
  border-left: none;
}

.chiller-theme .sidebar-footer > a:last-child {
  border-right: none;
}
.sidenav-active {
  color: #fff;
  background: #464a52;
}
.sidenav-active,
.sidenav-active span {
  color: #fff;
}
.sidenav-active,
.sidenav-active i {
  color: #16c7ff;
}
/*--------------------------------------------
				 users 
--------------------------------------------*/
.card {
  padding-top: 20px;
  margin: 10px 0 20px 0;
  background-color: rgba(214, 224, 226, 0.2);
  border-top-width: 0;
  border-bottom-width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.card.hovercard {
  position: relative;
  padding-top: 0;
  overflow: hidden;
  text-align: center;
  background-color: rgba(214, 224, 226, 0.8);
}

.card.hovercard .cardheader img {
  background-size: cover;
  height: 140px;
  width: 100%;
}
.card.hovercard .avatar {
  position: relative;
  top: -50px;
  margin-bottom: -50px;
}

.card.hovercard .avatar img {
  width: 120px;
  height: 120px;
  max-width: 120px;
  max-height: 120px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.5);
}

.card.hovercard .info {
  padding: 4px 8px 10px;
}

.card.hovercard .info .title {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
  color: #262626;
  vertical-align: middle;
}

.card.hovercard .info .desc {
  overflow: hidden;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  text-overflow: ellipsis;
}

.card.hovercard .bottom {
  padding: 0 20px;
  margin-bottom: 17px;
}

.bottom .btn {
  width: 100px;
  height: 32px;
}
.add-user-div {
  margin-bottom: -30px;
}

/*----------------------------------
			Dashboard
----------------------------------*/
.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px;
  background-color: #fff;
  height: 130px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  position: absolute;
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  position: absolute;
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

/*---------------------------------
			Category 
---------------------------------*/

.trash {
  text-decoration: none;
  font-size: 1.6em;
  color: rgb(209, 91, 71);
}
.edit {
  margin-right: 20px;
  text-decoration: none;
  font-size: 1.6em;
}
/* .panel-body {
	padding: 0px;
} */
.panel .fa,
.list-group-item .fa {
  margin-right: 5px;
}
.checkbox {
  display: inline-block;
  margin: 0px;
}
.checkbox img {
  margin-right: 10px;
}
.list-group-item:hover,
a.list-group-item:focus {
  cursor: pointer;
  text-decoration: none;
  background-color: rgb(245, 245, 245);
}
.list-group-item {
  padding: 15px;
  height: auto;
}
.list-group {
  margin-bottom: 0px;
}
#category-panel {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  /* background: #16c7ff; */
}
#category-panel i {
  font-size: 2.1em;
  padding: 5px;
  border-radius: 2px;
}
#category-panel i:hover {
  opacity: 0.7;
  cursor: pointer;
  /* background: #1e2229; */
  /* color: #fff; */
}
.action-buttons {
  margin-top: 15px;
  float: right;
}
.action-buttons button {
  width: 100px;
  margin-right: 5px;
}
.btn-file {
  position: relative;
  height: 42px;
  width: 100px;
  border: none;
  background: #fff;
  overflow: hidden;
}
.btn-file:hover {
  background: #fff;
}
.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  /* min-width: 100%; */
  /* min-height: 100%; */
  /* font-size: 100px; */
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
.btn-file label {
  background: #2266a5;
  padding: 8px;
  border-radius: 3px;
  color: #fff;
  margin-top: -5px !important;
}

#file-input {
  border-radius: 3px;
  padding: 2px;
  height: 40px;
}

/*-----------------------------------
			Common 
-----------------------------------*/
.dt-buttons {
  margin-bottom: -40px;
}
.dt-button {
  /* padding: 16px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  cursor: pointer;
  padding: 5px 20px 5px 20px;
  background-color: white;
  color: black;
  border: 1px solid #555555;
  border-radius: 5px;
}
.dt-button:hover {
  background-color: #555555;
  color: white;
}
#pagination {
  /* margin-top: 20px; */
  text-align: center;
}

/*-----------------------------------
			Order
-----------------------------------*/
#order-table tr {
  cursor: pointer !important;
}
#order-panel .panel-body {
  height: 150px;
  padding: 10px;
  position: relative;
}
.items-heading {
  font-size: 1.4em;
  margin-top: -60px;
  margin-bottom: -40px;
}
.items-heading .form-group {
  width: 20%;
  margin-left: 40%;
}
.items-heading .form-group div {
  opacity: 0.8;
  font-size: 0.7em;
}
/* #order-panel .panel-body label {
  position: absolute;
  left: 10px;
} */
.grid-info {
  display: grid;
  grid-template-columns: 1.5fr 2fr;
}
#pdf-btn .pdf-btn-div {
  float: right;
  margin-top: -60px;
  cursor: pointer;
}
/* #order-panel .panel-body span {
  position: absolute;
  left: 150px;
} */
#order-panel .panel-body div {
  /* margin-bottom: 4px; */
  /* border-top: 1px solid #31353d; */
}
.order-date-grid {
  margin-left: -15px;
}
.hedding h1 {
  color: #fff;
  font-size: 25px;
}
.hiddenRow {
  padding: 0 4px !important;
  background-color: #eeeeee;
  font-size: 13px;
}
.accordian-body span {
  color: #a2a2a2 !important;
}

.accordion-toggle[aria-expanded="true"] .fa-angle-right {
  display: none;
}
.accordion-toggle[aria-expanded="false"] .fa-angle-down {
  display: none;
}
.p td {
  background: #fff !important;
}
.card-container .product {
  position: relative;
  background-color: #eeeeee;
  box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19),
    2px 2px 6px 2px rgba(97, 95, 95, 0.19) !important ;
  overflow: hidden;
  width: 200px;
  height: 240px;
  display: inline-block;
  border-radius: 4px;
  /* margin: 20px; */
  padding: 10px;
}
.card-container .product img {
  position: relative;
  width: 120%;
  height: 120px;
  top: -15px;
  left: -15px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 70%);
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 70%);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.card-container .product img:hover {
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
  clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.card-container .product h2 {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 4px;
}
.card-container .product .description {
  text-align: left;
  font-size: 14px;
  max-height: 23px;
  overflow: hidden;
}
.card-container .product .price {
  position: absolute;
  bottom: -5px !important;
  font-size: 12px;
  left: 10px;
  cursor: pointer;
}
.card-container .product .btn {
  position: absolute;
  font-size: 11px;
  font-weight: bold;
  float: right;
  padding: 10px;
  border: 2px solid #31353d;
  border-radius: 20px;
  bottom: 10px;
  right: 10px;
  /* color: #00fefe; */
  cursor: pointer;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.card-container .product .btn.ok {
  background-color: rgba(0, 254, 254, 0);
}
.card-container .product .btn:hover {
  background: #1e2229;
  color: #fff;
  border: 2px solid #fff;
}
.card-container .product .quickview {
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
}
#product-btn span {
  padding: 6px;
  cursor: pointer;
  border-radius: 2px;
  font-size: 0.9em;
  background: #008cba;
  color: #fff;
  height: 20px !important;
}
#product-btn span:hover {
  opacity: 0.7;
}

/*-------------------------------------
			products 
-------------------------------------*/
.my-container {
  position: relative;
  width: 100%;
}

.add-new-product-image {
  padding: 15px;
  background: #bbbbbb;
  border-radius: 5px;
  transition: all 0.5s;
  cursor: pointer;
}
.add-new-product-image:hover {
  color: #fff;
  background: #525965;
}
.add-product-image {
  opacity: 0;
}

.display-img {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.my-container:hover .display-img {
  opacity: 0.3;
}

.my-container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #1e2229;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  padding: 16px 32px;
}

.update-product-image {
  opacity: 0;
}

.product_image img {
  max-width: 100%;
  height: auto;
}
ul.product_list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.product_grid a {
  text-decoration: none;
}
.product_item {
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19),
    2px 2px 6px 2px rgba(97, 95, 95, 0.19) !important ;
}
.product_sale {
  position: absolute;
  z-index: 99;
  right: -28px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
.product_image {
  position: relative;
  overflow: hidden;
}
.product_image img {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.product_image img:hover {
  -o-transform: scale(1.2, 1.2);
  -moz-transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
}
.product_title {
  float: left;
  width: 100%;
  text-transform: uppercase;
}
.product_title h5 {
  margin: auto;
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1;
  padding-bottom: 5px;
}
.product_price a {
  color: #000;
}
.price_old {
  color: #ea2e49;
  text-decoration: line-through;
  margin-right: 10px;
}
.product_price span {
  font-size: 1.1em;
  line-height: 1;
}
.product_desc p {
  margin: 0;
  line-height: 1.3;
  padding: 10px 0;
}
.product_buttons {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.product_buttons button:hover {
  /* color: #df0404; */
  opacity: 0.7;
  /* background: rgba(255, 255, 255, 0.5); */
  cursor: pointer;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .product_sale p {
    margin: 0;
    color: #fff;
    background: #ff0000;
    padding: 3px 30px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.4);
  }
  .product_values {
    float: left;
    width: calc(100% - 100px);
    padding: 0 10px;
  }
  .product_rating {
    margin-right: 10px;
  }
  .product_image {
    height: 150px;
    float: left;
    width: 100px;
  }

  .product_desc {
    overflow: hidden;
    float: left;
    line-height: 1;
  }
  .product_values .product_buttons {
    position: relative;
    text-align: left;
    float: right;
    margin-top: 40px;
  }
  .product_values .product_buttons .product_heart {
    color: #fff;
    background: #17a2b8;
    font-size: 1em;
    border-radius: 5%;
    width: auto;
    height: 40px;
    border: 1px solid #17a2b8;
  }
  .product_values .product_buttons .product_delete {
    color: #fff;
    background: #d9534f;
    font-size: 1em;
    border-radius: 5%;
    margin-left: 4px;
    width: auto;
    height: 40px;
    border: 1px solid #d9534f;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .product_image {
    height: 250px;
    width: 175px;
  }
  .product_values {
    width: calc(100% - 175px);
  }
}

@media only screen and (min-width: 678px) {
  .product_image {
    height: 150px;
    width: 100px;
  }
  .product_values {
    width: calc(100% - 100px);
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .product_image {
    height: 250px;
    width: 175px;
  }
  .product_values {
    width: calc(100% - 175px);
  }
  .product_desc {
    max-height: 200px;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
  .product_desc {
    max-height: 131px;
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .product_desc {
    max-height: 67px;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .product_title h5 {
    font-weight: bold;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  .product_sale {
    display: none;
  }
  .product_image img {
    position: relative;
  }
  .product_price span {
    float: left;
    width: 100%;
  }
  .product_desc {
    display: none;
  }
  .product_buttons {
    display: none;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.product img {
  max-width: 100%;
  height: auto;
}

.product--title {
  color: #008080;
  font-weight: bold;
  font-size: 1.5em;
  font-family: serif;
  padding: 10px 0 10px 30%;
  text-align: center;
}
.product--title h2 {
  display: inline-block;
  border-bottom: 2px dashed;
  margin-top: -5px !important;
}

.product--image,
.product--details {
  float: left;
  width: 50%;
}
.product--details {
  margin-top: 2%;
  font-size: 1.2em;
}
.product--image {
  padding-right: 2%;
  margin-top: -3% !important;
}
.product--image .icon-images img {
  height: 60px;
  margin: 0 10px 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
}

.product--image .icon-images img:hover {
  opacity: 0.7;
  border: 1px solid #a2a2a2;
}

.product--price .product--price_price {
  color: rgba(20, 180, 180, 0.9);
  font-size: 1.2em;
  margin-left: 10px;
  padding-right: 10px;
}

.product-category,
.product-featured {
  margin-top: 10px;
  font-size: 1em;
}
/* #add-edit-product .form-group label:after {
	content: '*';
	color: red;
} */
.product-category span,
.product-featured span {
  margin-left: 10px;
}

.product--price .product--price_offer {
  color: indianred;
  font-size: 1em;
  animation: blinker 1s linear infinite;
  font-weight: bolder;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.product--desc p {
  margin: 20px 0px;
}

.product--size span {
  margin-left: 10px;
}
.product--quantity input {
  border: 0;
  outline: 0;
  border-left: 1px dotted;
  border-right: 1px dotted;
  border-bottom: 1px dashed;
  padding: 1px 3px;
  text-align: center;
}
.product--quantity input::-webkit-inner-spin-button,
.product--quantity input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-to-cart {
  margin: 10px 10px 10px 0;
}

.btn.btn--cart {
  border: 1px solid #007bff;
  border-radius: 4px;
  margin-top: 10%;
  padding: 3px 30px 3px 5px;
  box-shadow: 0px 0px 3px 0px #007bff;
  position: relative;
  overflow: hidden;
  text-align: left;
  font-size: 1em;
}
.btn.btn--cart:after {
  content: "⇨";
  padding-left: 2px;
  position: absolute;
  right: 150%;
  transition: all 0.4s;
  color: #fff;
}
.btn.btn--cart:hover,
.btn.btn--cart:focus {
  background: #007bff;
  color: #fff;
  text-shadow: 2px 2px 3px #666;
  transition: all 0.4s;
}
.btn.btn--cart:hover:after,
.btn.btn--cart:focus:after {
  right: 2%;
}

#product-image {
  cursor: pointer;
}
#product-image:hover {
  opacity: 0.8;
}
#product-image-input {
  display: none;
}

@media screen and (max-width: 500px) {
  .product--image,
  .product--details {
    float: none;
    width: 100%;
    padding: 10px 5px;
    margin: 5px 10px;
  }
}
.display-img {
  height: auto;
}
/*-------------------------------------
			Banners 
--------------------------------------*/

[class^="imghvr-"],
[class*=" imghvr-"] {
  position: relative;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  background-color: #2266a5;
  color: #fff;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}

[class^="imghvr-"] > img,
[class*=" imghvr-"] > img {
  vertical-align: top;
  max-width: 100%;
}

[class^="imghvr-"] figcaption,
[class*=" imghvr-"] figcaption {
  background-color: #2266a5;
  padding: 30px;
  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  color: #ffffff;
}

[class^="imghvr-"] figcaption h3 {
  color: #ffffff;
}

[class^="imghvr-"] a,
[class*=" imghvr-"] a {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

[class^="imghvr-"],
[class*=" imghvr-"],
[class^="imghvr-"]:before,
[class^="imghvr-"]:after,
[class*=" imghvr-"]:before,
[class*=" imghvr-"]:after,
[class^="imghvr-"] *,
[class*=" imghvr-"] *,
[class^="imghvr-"] *:before,
[class^="imghvr-"] *:after,
[class*=" imghvr-"] *:before,
[class*=" imghvr-"] *:after {
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

/* imghvr-hinge-*
   ----------------------------- */
[class^="imghvr-hinge-"],
[class*=" imghvr-hinge-"] {
  -webkit-perspective: 50em;
  -moz-perspective: 50em;
  perspective: 50em;
}

[class^="imghvr-hinge-"] figcaption,
[class*=" imghvr-hinge-"] figcaption {
  opacity: 0;
  z-index: 1;
}

[class^="imghvr-hinge-"]:hover img,
[class*=" imghvr-hinge-"]:hover img {
  opacity: 0;
}

[class^="imghvr-hinge-"]:hover figcaption,
[class*=" imghvr-hinge-"]:hover figcaption {
  opacity: 1;
  -webkit-transition-delay: 0.21s;
  -moz-transition-delay: 0.21s;
  transition-delay: 0.21s;
}

/* imghvr-hinge-left
   ----------------------------- */
.imghvr-hinge-left {
  margin-bottom: 20px;
}
.imghvr-hinge-left img {
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.imghvr-hinge-left figcaption {
  -webkit-transform: rotateY(-90deg);
  -moz-transform: rotateY(-90deg);
  -ms-transform: rotateY(-90deg);
  -o-transform: rotateY(-90deg);
  transform: rotateY(-90deg);
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.imghvr-hinge-left:hover > img {
  -webkit-transform: rotateY(90deg);
  -moz-transform: rotateY(90deg);
  -ms-transform: rotateY(90deg);
  -o-transform: rotateY(90deg);
  transform: rotateY(90deg);
}

.imghvr-hinge-left:hover figcaption {
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
  -ms-transform: rotateY(0);
  -o-transform: rotateY(0);
  transform: rotateY(0);
}

.image-label {
  padding: 20px;
}
.image-label span {
  padding: 10px 20% 10px 20%;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0 2px 1px 0 rgba(97, 95, 95, 0.19),
    2px 2px 6px 2px rgba(97, 95, 95, 0.19) !important ;
}
div.upload {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* margin-top: 0px !important; */
  display: inline-block;
  height: 45px;
  margin-bottom: 32px;
  padding: 10px 80px 3px 15px;
  position: relative;
  width: auto;
}

div.upload:hover {
  opacity: 0.95;
}

div.upload .banner-input {
  display: input-block;
  /* width: 100%; */
  opacity: 0;
  background: #007bff !important;
  /* padding-top: 15px; */
  cursor: pointer;
  position: absolute;
  left: 0;
}
.uploadButton {
  border: none;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 37px;
  margin-left: 5px;
  width: auto;
  padding: 25px;
  box-sizing: content-box;
}

#upload-file {
  padding: 4px 20px 4px 20px;
  border-radius: 5px;
}

.fileName {
  top: 10px;
  font-family: Arial;
  font-size: 16px;
  color: #1e2229;
}

/*-------------------------------------
			settings 
-------------------------------------*/
#update-notification {
  /* margin-top: 25px; */
}

/*-------------------------------------
			media queries 
--------------------------------------*/
@media (max-width: 767px) {
  .items-heading .form-group {
    width: 40%;
    margin-left: 0;
  }
  #category-panel {
    text-align: center;
    padding: 5px 10px 5px 10px;
  }
  .order-date-grid {
    margin-left: -10px;
  }
  .card-container {
    text-align: center;
  }
  .product--title {
    padding: 5px 0 10px 3%;
  }
  .product--details {
    font-size: 0.9em;
  }
  .action-buttons {
    float: none;
  }
  .action-buttons button {
    width: 60px;
    font-size: 0.8em;
  }
  .list-group-item {
    text-align: center;
  }
  #update-notification {
    text-align: center;
  }
}
